import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import Layout from '@/layout/LayoutIndex.vue'

const routes = [
//   {
//     path: '',
//     component: Layout,
//     redirect: '/home',
//     children: [
//       {
//         path: '/home',
//         component: HomeView,
//         name: 'Home',
//         meta: { title: '首页', icon: 'dashboard', affix: true }
//       }
//     ]
// }
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    name: '/my',
    path: '/',
    meta: {
      title: '91查题',
      icon: 'contact'
    },
    component: () => import('@/views/MyIndex.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '默认标题'; // 在路由元信息中设置标题
  next();
});

export default router
