import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

//import {  Icon, Grid, GridItem, CellGroup, Cell } from 'vant';
import { List , PullRefresh, Popup, Icon ,Toast } from 'vant'
import 'vant/lib/index.css';
import './assets/styles/index.scss'

createApp(App)
//    .use(Icon).use(Grid).use(GridItem).use(CellGroup).use(Cell)
    .use(List)
    .use(PullRefresh)
    .use(Popup)
    .use(Icon)
    .use(Toast)
    .use(router)
    .mount('#app')

/* eslint-disable */
document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
    WeixinJSBridge.call('hideOptionMenu');
});
/* eslint-enable */